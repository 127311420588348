import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Action } from '../models/action';
import { GitRepo } from '../models/git-repo';
import { Commit,  Service } from '../models/service';
import { defaultTechnos, Techno } from '../models/techno';
import { contextes } from '../enums/contextes';
import { StackData, typeService } from '../models/stack';
import { Project } from '../models/project';
import { GitProvider, gitProviderName } from '../models/git-provider';
import { cloudProviders } from '../enums/cloudprovider';
import { template } from '../data/data-source';
import { inject, Injectable } from '@angular/core';
import { ApiServiceInterface } from './api.service';

export interface DomainConfigRequest{
  path : string;
  prefix: string;
  isRoot : boolean;
  serviceId : string;
}

const  accounts = [ // mock
  {
    _id : 1,
    name : "My AWS account",
    provider : cloudProviders.AWS
  },
  {
    _id : 2,
    name : "My GCP account",
    provider : cloudProviders.GCP
  },
  {
    _id : 3,
    name : "My OVH account",
    provider : cloudProviders.OVH
  }
]


//export final est dans le api-service
//permet de gérer les mock
@Injectable()
export class MainApiService implements ApiServiceInterface{

  apiUrl : string;
  http = inject(HttpClient);

  constructor() {
    this.apiUrl = `${environment.api.protocol}://${environment.api.domain}`
    if(environment.api.port){
      this.apiUrl += `:${environment.api.port}`
    }
  }

  quiSuisje(){
    return this.http.get(`${this.apiUrl}/qui_suis_je`, {withCredentials: true})
  }

  quiSuisjeKeycloak(){
    return this.http.get(`${this.apiUrl}/users/whoami`, {withCredentials: true})
  }

  inviteUserKeycloak(email : string){
    return this.http.put(`${this.apiUrl}/users/invite?`,{
      email : email
    } , {withCredentials: true})
  }

  listUsersKeycloak(){
    return this.http.get(`${this.apiUrl}/users`, {withCredentials: true})
  }

  connect(provider : string, finalPath = '', options : {params?} = {}){
    if(finalPath[0] === '/'){
     finalPath = finalPath.slice(1);
    }
    const params = {
      ...options.params,
      'final-url' : `${window.location.origin}/${finalPath}`
    }
    return this.http.get(`${this.apiUrl}/connect/${provider}/redirect_url`,
      {
        params,
        withCredentials: true,
        responseType: 'text'
      });
  }

  reportUrlForIframe(service_id : string, context : contextes, report_id : string){
    return `${this.apiUrl}/report/${service_id}/${context}/${report_id}`
  }

  generateReport(service_id : string, context : contextes){
    return this.http.post(`${this.apiUrl}/report/${service_id}/${context}`, {}, {
      withCredentials: true,
      responseType: 'text'
    })
  }

  getRepos(provider : string){
    return this.http.get<GitRepo[]>(`${this.apiUrl}/git/${provider}/repos`,{
      withCredentials : true,
      responseType : 'json'
    }).pipe(
      tap(repos=>{
         repos.forEach(repo=>repo.provider = provider);
      })
    )
  }

  getBranchesOAuth(repo : GitRepo):Observable<any>{
    return this.http.get(`${this.apiUrl}/git/${repo.provider}/repos/${repo.gitId}/branches`,{
      withCredentials : true,
      responseType : 'json'
    })
  }

  getBranches(service : Service):Observable<any>{
    return this.http.get(`${this.apiUrl}/managed-services/${service._id}/branches`,{
      withCredentials : true,
      responseType : 'json'
    })
  }

  setBranch(service : Service, context : contextes | string, branchName: string):Observable<any>{
    return this.http.put(`${this.apiUrl}/managed-services/${service._id}/${context}/branch`,{
      branchName
    },{
      withCredentials : true,
      responseType : 'text'
    })
  }


  setDisplayName(service : Service, displayName: string):Observable<any>{
    return this.http.put(`${this.apiUrl}/managed-services/${service._id}`,{
      displayName
    },{
      withCredentials : true,
      responseType : 'text'
    })
  }

  getProject(project: Project){
    return this.http.get(`${this.apiUrl}/projects/${project._id}/`,{
      withCredentials : true,
      responseType : 'json'
    })
  }

  getTechno(repo : GitRepo){
    return this.http.get(`${this.apiUrl}/git/${repo.provider}/repos/${repo.gitId}/branches/${encodeURIComponent(repo.stagingBranch)}/techno`, {
      withCredentials : true,
      responseType : 'json'
    }).pipe(
      tap((techno : {id, back, front})=>{
        repo.techno = Techno.constructFromDb(techno);
      })
    )
  }

  addProject(projectName: string, stack : StackData, gitProvider : GitProvider){

    const params = {};
    let body = {};
    const front = stack.content.find((c)=>c.type === typeService.FRONT)
    const back = stack.content.find((c)=>(c.type === typeService.BACKEND) || (c.type === typeService.CMS))
    const workflow = stack.content.find((c)=>c.type === typeService.WORKFLOW)
    if((gitProvider.provider === gitProviderName.GITHUB) || (gitProvider.provider === gitProviderName.GITLAB)){
      body = {
        projectName : projectName,
        front : front?.techno,
        back : back?.techno,
        workflow : workflow?.techno,
        gitProvider: gitProvider
      }
    } else {
      body = {
        projectName : projectName,
        front : front?.techno,
        back : back?.techno,
        workflow : workflow?.techno,
      }
    }
    let url = `${this.apiUrl}/managed-services/project`
    return this.http.post(url,
      body,{
        params,
        withCredentials : true,
        responseType : 'json'
      })
  }



  addService(project: Project, serviceName: string, technoId:number){
    const body : { version?: string; serviceName?: any ; technoId : number } = {
      serviceName,
      technoId
    }
    return this.http.post(`${this.apiUrl}/managed-services/project/${project._id}/service`,
      body,{
        withCredentials : true,
        responseType : 'json'
      })
  }

  getS3UrlForFileInput(action : Action, key : string){
    return this.http.get(`${this.apiUrl}/actions/${action._id.toString()}/s3-input-token/${key}`, {
      withCredentials: true,
      responseType : 'json'
    })
  }

  sendS3FileFromPrePost(
    prepost : {
      url : string,
      fields : {
        [key : string] : string
      }
    },
    file : File
    ){
      const formData = new FormData();
      for(const key in prepost.fields){
        formData.append(key, prepost.fields[key])
      }
      formData.append('file', file);
      return this.http.post(prepost.url, formData)
  }

  sendInputs(action : Action, inputs : {[key : string] : string}){
    return this.http.post(`${this.apiUrl}/actions/${action._id.toString()}/input`, inputs, {
      withCredentials: true,
      responseType : 'text'
    })
  }

  deploy(service : Service, commit : Commit, context : string){
    const params = {
      commitId : commit.commitId
    }
    return this.http.put(
      `${this.apiUrl}/managed-services/${service._id}/${commit.ctxtToDeploy || context}/deploy`,
      {},
      {
        params,
        withCredentials : true,
        responseType : 'json'
    })
  }

  getCommits(service : Service, context : string){
    return this.http.get(`${this.apiUrl}/managed-services/${service._id}/${context}/deploiements`,{
      withCredentials : true,
      responseType : 'json'
    })
  }

  getActions( service? : Service, otherFilter? : {[key : string] : string}){
    let params = {
    }
    if(service){
      params['filter.serviceId'] = service._id.toString()
    }
    if(otherFilter){
      params = {
        ...params,
        ...otherFilter
      }
    }

    return this.http.get(`${this.apiUrl}/actions`,{
      withCredentials : true,
      responseType : 'json',
      params
    })
  }

  getDnsUpdateAction(project : Project){
    return this.http.get(`${this.apiUrl}/actions/dns-update-workflow/${project._id.toString()}`,{
      withCredentials : true,
      responseType : 'json'
    })
  }

  watchAction(action : Action){
    return this.http.get(`${this.apiUrl}/actions/${action._id}/watch`,{
      withCredentials : true,
      responseType : 'text'
    })
  }

  getActionLog(action : Action){
    return this.http.get(`${this.apiUrl}/actions/${action._id}/logs`,{
      withCredentials : true,
      responseType : 'json',
    })
  }

  // Git actions
    getDiffBranch(service:Service, context: contextes, origin:string, destination:string){
      return this.http.get(`${this.apiUrl}/managed-services/${service._id}/diff/${origin}/${destination}`,{
        withCredentials : true,
        responseType : 'json',
      })
    }


    mergeBranch(service:Service, context: contextes, origin:string, destination:string){
      return this.http.post(`${this.apiUrl}/managed-services/${service._id}/merge/${origin}/${destination}`,
        {},
      {
        withCredentials : true,
        responseType : 'text',
      })
    }

    mergeBranchWithCommit(service:Service, context: contextes, origin:string, destination:string, commitId:string){
      return this.http.post(`${this.apiUrl}/managed-services/${service._id}/merge/${origin}/${commitId}/${destination}`,
        {},
        {
        withCredentials : true,
        responseType: 'text',
      })
    }

    getGitWeburl(service:Service, branchName?:string){
      // add context to open on right branch
      if(branchName){
        return this.http.get(`${this.apiUrl}/managed-services/${service._id}/gitweburl/${branchName}`,{
          withCredentials : true,
          responseType : 'text',
        })
      } else {
        return this.http.get(`${this.apiUrl}/managed-services/${service._id}/gitweburl`,{
          withCredentials : true,
          responseType : 'text',
        })
      }
    }


    getMergeRequestsList(service:Service, destination:string){
      return this.http.get(`${this.apiUrl}/managed-services/${service._id}/mergerequests/${destination}`,{
        withCredentials : true,
        responseType : 'json',
      })
    }

    getMergeRequestsFromList(service:Service, basebranch:string){
   //  return of([]);
      return this.http.get(`${this.apiUrl}/managed-services/${service._id}/mergerequestsfrom/${basebranch}`,{
        withCredentials : true,
        responseType : 'json',
      })
    }

    getUrl(service:Service, context: contextes){
      return this.http.get(`${this.apiUrl}/managed-services/${service._id}/${context}/url`,{
        withCredentials : true,
        responseType : 'text',
      })
    }

    getConfigManagerToolUrl(service?:Service, context?:contextes){

      /*return this.http.get(`${this.apiUrl}/managed-services/${service._id}/${context}/url`,{
        withCredentials : true,
        responseType : 'text',
      })*/


      return of().pipe(
        delay(0)
      )
    }

  getUsersProfile(ids : string[]){
    return of([]).pipe(
      delay(0)
    )
  }



  getAccounts(){
    return of(accounts).pipe(
      delay(0)
    )
  }

  updateAccess(role:any){

    return of("ok").pipe(
      delay(0)
    )
  }


  addNewDomain(project: Project, domainName:string){
    const params = {
      domainName
    }
    return this.http.post(`${this.apiUrl}/managed-services/project/${project._id}/domain`,{}, {
      params,
      withCredentials : true,
      responseType : 'text',
    })
  }

  setAsMainDomain(project: Project, domainName:string){
    const params = {
      domainName
    }

    return this.http.put(`${this.apiUrl}/managed-services/project/${project._id}/domain`, {}, {
      params,
      withCredentials : true,
      responseType : 'text',
    })
  }

  removeDomain(project: Project, domainName:string){
    const params = {
      domainName
    }

    return this.http.delete(`${this.apiUrl}/managed-services/project/${project._id}/domain`,  {
      params,
      withCredentials : true,
      responseType : 'text',
    })
  }

  changeDomain(project:Project, domainName: string){
    const params = {
      domainName
    }

    return this.http.put(`${this.apiUrl}/managed-services/project/${project._id}/service`,{},{
      params,
      withCredentials : true,
      responseType : 'text'
    });
  }

  changeServicesDomainConfig(project : Project, newDomainConfig : DomainConfigRequest[]){
    const body = {
      newDomainConfig
    }
    return this.http.put(`${this.apiUrl}/managed-services/project/${project._id}/servicesConfig`,
    body, {
      withCredentials : true,
      responseType : 'json',
    })
  }

  cancelRequest(project : Project, serviceId : string){
    return this.http.put(`${this.apiUrl}/managed-services/project/${project._id}/rootService/${serviceId}`,{},{
      withCredentials : true,
      responseType : 'text'
    });
  }

  cancelDnsUpdateAction(project : Project){
    return this.http.put(`${this.apiUrl}/actions/dns-update-workflow/${project._id.toString()}/cancel`, {},{
      withCredentials : true,
      responseType : 'text'
    })
  }

  getTemplates(){
    return of(template).pipe(
      delay(0)
    )
  }

  getTechnos(){
    const technos:  Techno[]  = Array.from(defaultTechnos, ([name, value]) => (value ));
    return of(technos).pipe(
      delay(0)
    )
  }
}
